var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-h4 mb-2"},[_vm._v(_vm._s(_vm.$t('title')))]),_c('v-row',{staticClass:"align-center mb-5"},[_vm._l((_vm.filter),function(item,index){return [_c('v-col',{key:("filter-item-" + index),attrs:{"cols":"3"}},[(item.type === 'select')?[_c('v-select',{attrs:{"items":item.list,"item-text":"name","item-value":"id","label":item.name,"color":"teal","clearable":""},on:{"change":function($event){return _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e(),(item.type === 'text')?[_c('v-text-field',{attrs:{"label":item.name,"rules":item.rules,"color":"teal","clearable":""},on:{"change":function($event){return _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e()],2)]}),_c('v-col',{attrs:{"cols":"auto ms-auto"}},[_c('v-btn',{attrs:{"color":"teal","dark":"","to":"/clients/add"}},[_vm._v(_vm._s(_vm.$t('btn.add')))])],1)],2),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"footer-props":_vm.footer,"server-items-length":_vm.total,"page":_vm.page,"loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.UF_TYPE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.find = _vm.clientTypes.find(function (type) { return type.id === item.UF_TYPE; })) ? _vm.find.name : '-')+" ")]}},{key:"item.UF_ACTIVE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.UF_ACTIVE === '1') ? _vm.$t('yes') : _vm.$t('no'))+" ")]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"teal"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/clients/" + (item.ID))}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('btn.detail'))+" ")])],1),_c('v-list-item',{attrs:{"to":("/orders/add/client/" + (item.ID) + "/")}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('btn.addOrder'))+" ")])],1),_c('v-list-item',{attrs:{"to":("/orders/client/" + (item.ID) + "/")}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('btn.clientOrders'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }